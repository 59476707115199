<template>
  <div class="main-div calendar-page">
    <PageBack />
    <div class="calendar-content" v-if="!isWap">
      <div class="calendar-top">
        <div class="title">
          财经日历<span>{{ year }}年{{ month }}月</span>
        </div>
        <div class="calendar-date">
          <div class="date-left" @click="handleDate('left', '')">
            <i></i>
          </div>
          <div class="date-info">
            <p class="week-date">
              <span class="week-date-span">日</span>
              <span class="week-date-span">一</span>
              <span class="week-date-span">二</span>
              <span class="week-date-span">三</span>
              <span class="week-date-span">四</span>
              <span class="week-date-span">五</span>
              <span class="week-date-span">六</span>
            </p>
            <p class="day-date">
              <span
                v-for="(item, index) in dateArr"
                :key="index"
                class="day-date-span"
                @click="handleDate('', index)"
                :class="{ act: item.day == currentDay && item.month == month }"
                >{{ item.day }}</span
              >
            </p>
          </div>
          <div class="date-right" @click="handleDate('right', '')"><i></i></div>
        </div>
      </div>
      <div class="withe-bg">
        <div class="calendar-info">
          <table>
            <thead>
              <tr>
                <th>时间</th>
                <th>国家/区</th>
                <th>指标名称</th>
                <th>重要性</th>
                <th>前值</th>
                <th>预测值</th>
                <th>公布值</th>
                <th>影响</th>
              </tr>
            </thead>
            <tbody v-if="calendarDataList.length > 0">
              <tr v-for="(item, index) in calendarDataList" :key="index">
                <td v-if="item.rowspan" :rowspan="item.rowspan" class="td-time">
                  {{ item.time_show || "" }}
                </td>
                <td>
                  <img
                    v-if="imgList.includes(item.country)"
                    :src="
                      require(`../assets/images/country/${item.country}.jpg`)
                    "
                    :alt="item.country"
                  />
                  <img
                    v-else
                    :src="require('../assets/images/country/默认.jpg')"
                    :alt="item.country"
                  />
                </td>
                <td>{{ item.title }}</td>
                <td>
                  <i
                    class="icon-star icon-star-action"
                    v-for="i in parseInt(item.star)"
                    :key="index + '-' + i"
                  ></i>
                  <i
                    class="icon-star"
                    v-for="i in 5 - item.star"
                    :key="index + '-' + (6 - i)"
                  ></i>
                </td>
                <td>{{ item.previous || "--" }}</td>
                <td>{{ item.consensus || "--" }}</td>
                <td>{{ item.actual || "--" }}</td>
                <td>
                  <span
                    class="status"
                    :class="{
                      green: item.status_name.indexOf('利空') > -1,
                      red: item.status_name.indexOf('利多') > -1,
                    }"
                  >
                    {{ item.status_name || "--" }}
                  </span>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="8">{{ isLoading ? "加载中..." : "暂无数据" }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="calendar-top event-top">
        <div class="title">财经事件</div>
      </div>
      <div class="calendar-info event-list">
        <template v-if="eventDataList.length > 0">
          <div
            class="list-item event-item"
            v-for="(item, index) in eventDataList"
            :key="index"
          >
            <div class="item-left event-left">
              <img
                v-if="imgList.includes(item.country)"
                :src="require(`../assets/images/country/${item.country}-r.png`)"
                :alt="item.country"
              />
              <img
                v-else
                :src="require('../assets/images/country/默认-r.png')"
                :alt="item.country"
              />
              <p class="country-time">
                <span class="country">{{ item.country }}</span>
                <span class="time">{{ item.time }}</span>
              </p>
            </div>
            <div class="item-right event-right">
              <p class="title">{{ item.title }}</p>
              <span class="star span-item">
                <i
                  class="icon-star icon-star-action"
                  v-for="i in parseInt(item.star)"
                  :key="index + '-' + i"
                ></i>
                <i
                  class="icon-star"
                  v-for="i in 5 - item.star"
                  :key="index + '-' + (6 - i)"
                ></i>
              </span>
            </div>
          </div>
        </template>
        <p v-else class="no-data">
          {{ isLoading ? "加载中..." : "暂无数据" }}
        </p>
      </div>
    </div>
    <div class="calendar-content" v-else>
      <div class="calendar-top">
        <div class="title">财经日历</div>
        <div class="calendar-date">
          <div class="date-icon date-day" @click="searchTimeShow = true"></div>
          <div class="date-info">
            <p
              class="week-day"
              v-for="(item, index) in wapDateArr"
              :key="index"
              @click="handleDate('', index)"
              :class="{ act: item.day == currentDay && item.month == month }"
            >
              <span class="day-date-span">{{ item.month }}.{{ item.day }}</span>
              <span class="week-date-span">{{ wapWeekDay[index] }}</span>
            </p>
          </div>
          <div class="date-icon date-search" @click="handleShowSearch"></div>
        </div>
        <div class="info-type">
          <p
            class="type-p"
            :class="{ act: infoType == 1 }"
            @click="infoType = 1"
          >
            财经数据
          </p>
          <p
            class="type-p"
            :class="{ act: infoType == 2 }"
            @click="infoType = 2"
          >
            财经事件
          </p>
        </div>
      </div>
      <div class="calendar-info calendar-list">
        <template v-if="infoType == 1 && calendarFilterData.length > 0">
          <div
            class="list-item"
            v-for="(item, index) in calendarFilterData"
            :key="index"
          >
            <div class="item-left">
              <img
                v-if="imgList.includes(item.country)"
                :src="require(`../assets/images/country/${item.country}-r.png`)"
                :alt="item.country"
              />
              <img
                v-else
                :src="require('../assets/images/country/默认-r.png')"
                :alt="item.country"
              />
            </div>
            <div class="item-right">
              <p class="time-star-status">
                <span class="time span-item">{{ item.time_show }}</span>
                <span class="star span-item">
                  <i
                    class="icon-star icon-star-action"
                    v-for="i in parseInt(item.star)"
                    :key="index + '-' + i"
                  ></i>
                  <i
                    class="icon-star"
                    v-for="i in 5 - item.star"
                    :key="index + '-' + (6 - i)"
                  ></i>
                </span>
                <span
                  class="status"
                  :class="{
                    green: item.status_name.indexOf('利空') > -1,
                    red: item.status_name.indexOf('利多') > -1,
                  }"
                >
                  {{ item.status_name || "--" }}
                </span>
              </p>
              <p class="title">{{ item.title }}</p>
              <p class="value">
                <span class="previous span-item"
                  >前值：{{ item.previous || "--" }}</span
                >
                <span class="consensus span-item"
                  >预测值：{{ item.consensus || "--" }}</span
                >
                <span class="actual">公布值：{{ item.actual || "--" }}</span>
              </p>
            </div>
          </div>
        </template>
        <template v-else-if="infoType == 2 && eventFilterData.length > 0">
          <div
            class="list-item"
            v-for="(item, index) in eventFilterData"
            :key="index"
          >
            <div class="item-left">
              <img
                v-if="imgList.includes(item.country)"
                :src="require(`../assets/images/country/${item.country}-r.png`)"
                :alt="item.country"
              />
              <img
                v-else
                :src="require('../assets/images/country/默认-r.png')"
                :alt="item.country"
              />
            </div>
            <div class="item-right event-right">
              <div class="time-star-status">
                <p>
                  <span>{{ item.country }}</span
                  ><span class="time">{{ item.time }}</span>
                </p>

                <span class="star span-item">
                  <i
                    class="icon-star icon-star-action"
                    v-for="i in parseInt(item.star)"
                    :key="index + '-' + i"
                  ></i>
                  <i
                    class="icon-star"
                    v-for="i in 5 - item.star"
                    :key="index + '-' + (6 - i)"
                  ></i>
                </span>
              </div>
              <p class="title">{{ item.title }}</p>
            </div>
          </div>
        </template>
        <p v-else class="no-data">
          {{ isLoading ? "加载中..." : "暂无数据" }}
        </p>
      </div>
    </div>
    <van-popup
      v-model="searchTimeShow"
      position="bottom"
      :overlay="true"
      v-if="isWap"
    >
      <van-datetime-picker
        v-model="datetimepickerDate"
        type="date"
        @confirm="searchSave"
        @cancel="searchCancel"
      />
    </van-popup>
    <Dialog
      ref="search"
      :dialogObj="dialogObj"
      @confirm="handleConfirm"
      @cancel="handleCancel"
    >
      <div class="dialog-detail">
        <div class="search-div area">
          <p class="title">国家</p>
          <div class="search-list">
            <span
              class="search-item"
              v-for="item in searchArea"
              :key="item"
              :class="{ act: area == item }"
              @click="area = item"
              >{{ item }}</span
            >
          </div>
        </div>
        <div class="search-div star">
          <p class="title">重要性</p>
          <div class="search-list">
            <span
              class="search-item"
              :class="{ act: star == '重要' }"
              @click="star = '重要'"
              >重要</span
            >
            <span
              class="search-item"
              :class="{ act: star == '不重要' }"
              @click="star = '不重要'"
              >不重要</span
            >
          </div>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import PageBack from "@/components/pageBack.vue";
import common from "@/apis/common";
import { formatDate } from "@/utils/tool.js";
import Dialog from "@/components/dialog.vue";
export default {
  name: "Calendar",
  components: {
    PageBack,
    Dialog,
  },
  data() {
    return {
      dateArr: [], //日期数组
      wapDateArr: [], //wap日期数组
      wapWeekDay: [], //wap星期几数组
      currentDay: "",
      year: "",
      month: "",
      calendarDataList: [],
      isLoading: true,
      imgList: [
        "奥地利",
        "澳大利亚",
        "德国",
        "法国",
        "韩国",
        "加拿大",
        "美国",
        "默认",
        "欧元区",
        "日本",
        "瑞士",
        "西班牙",
        "新加坡",
        "新西兰",
        "意大利",
        "印度",
        "英国",
        "中国",
      ],
      searchTimeShow: false, //是否显示时间选择
      datetimepickerDate: new Date(),
      dialogObj: {},
      searchArea: [
        "全部",
        "中国",
        "美国",
        "日本",
        "英国",
        "加拿大",
        "瑞士",
        "澳大利亚",
      ],
      search: {
        //点击重置/确定时再赋值
        area: "全部",
        star: "",
      },
      area: "全部", //选择时赋值
      star: "", //选择时赋值
      infoType: 1, //信息类型1财经数据，2财经事件
      eventDataList: [], //财经事件列表
    };
  },
  computed: {
    isWap() {
      return this.$store.state.common.isWap;
    },
    calendarFilterData() {
      let data = this.calendarDataList.filter((item) => {
        if (this.search.area && this.search.area != "全部") {
          //过滤地区
          if (item.country.indexOf(this.search.area) != -1) {
            //过滤重要性
            return this.filterStar(item);
          } else {
            return false;
          }
        } else {
          //全部地区  过滤重要性
          return this.filterStar(item);
        }
      });
      return data;
    },
    eventFilterData() {
      let data = this.eventDataList.filter((item) => {
        if (this.search.area && this.search.area != "全部") {
          //过滤地区
          if (item.country.indexOf(this.search.area) != -1) {
            //过滤重要性
            return this.filterStar(item);
          } else {
            return false;
          }
        } else {
          //全部地区  过滤重要性
          return this.filterStar(item);
        }
      });
      return data;
    },
  },
  methods: {
    //获取当前年月日，显示的时间数组
    getCurrentDay() {
      let date = new Date();
      this.year = date.getFullYear();
      this.month = this.formData(date.getMonth() + 1);
      this.currentDay = this.formData(date.getDate());
      let weekDay = date.getDay(); //获取周几
      //获取日期数组
      let leftDay = this.getBeforeDayArr(
        weekDay,
        this.year,
        this.month,
        this.currentDay
      );
      let rightDay = this.getAfterDayArr(
        6 - weekDay,
        this.year,
        this.month,
        this.currentDay
      );
      this.dateArr = leftDay.concat(
        [{ year: this.year, month: this.month, day: this.currentDay }],
        rightDay
      );
      //获取wap端显示的当前开始3天内日期
      this.getWapDateArr(2, weekDay);
      //获取当前日期的数据
      this.getDataList();
    },
    //点击日期
    handleDate(type, index) {
      if (!type) {
        //选中日期  获取新日期数据
        let date = this.isWap ? this.wapDateArr : this.dateArr;
        this.currentDay = date[index].day;
        this.month = date[index].month;
        this.year = date[index].year;
        this.getDataList();
      } else if (type == "left") {
        //日期往后推7天
        this.dateArr = this.getBeforeDayArr(
          7,
          this.dateArr[0].year,
          this.dateArr[0].month,
          this.dateArr[0].day
        );
      } else if (type == "right") {
        //日期往前推7天
        let index = this.dateArr.length - 1;
        this.dateArr = this.getAfterDayArr(
          7,
          this.dateArr[index].year,
          this.dateArr[index].month,
          this.dateArr[index].day
        );
      }
    },
    //获取左侧日期数组
    getBeforeDayArr(num, year, month, currDay) {
      let dayArr = [];
      //左侧填充日期天数num
      for (let i = 0; i < num; i++) {
        //判断跨月计算方式
        if (currDay == 1) {
          //获取上月的日期
          if (month == 1) {
            month = 12;
            year = parseInt(year) - 1;
          } else {
            month = this.formData(parseInt(month) - 1);
          }
          currDay = this.getMonthDay(year, month);
        } else {
          currDay = this.formData(parseInt(currDay) - 1);
        }
        dayArr.unshift({ year: year, month: month, day: currDay });
      }
      return dayArr;
    },
    //获取右侧日期数组
    getAfterDayArr(num, year, month, currDay) {
      let dayArr = [];
      //左侧填充日期天数num
      for (let i = 0; i < num; i++) {
        if (currDay > 27) {
          //有可能下月的日期  获取当月天数
          let day = this.getMonthDay(this.year, month);
          if (currDay >= day) {
            //开始下月日期
            if (month == 12) {
              month = 1;
              year = parseInt(year) + 1;
            } else {
              month = this.formData(parseInt(month) + 1);
            }
            currDay = "01";
          } else {
            currDay = this.formData(parseInt(currDay) + 1);
          }
        } else {
          currDay = this.formData(parseInt(currDay) + 1);
        }
        dayArr.push({ year: year, month: month, day: currDay });
      }
      return dayArr;
    },
    //格式化数字
    formData(val) {
      return val >= 10 ? val : "0" + val;
    },
    //获取月份天数
    getMonthDay(year, month) {
      //由于JavaScript中day的范围为1~31中的值，所以当设为0时，会向前一天，也即表示上个月的最后一天
      var d = new Date(year, month, 0);
      return d.getDate();
    },
    //根据日期获取数据
    getDataList() {
      this.isLoading = true;
      common
        .getCalendar({ Date: `${this.year}-${this.month}-${this.currentDay}` })
        .then((res) => {
          this.isLoading = false;
          if (res.success) {
            this.calendarDataList = this.formateList(res.result.calendarLst);
            this.eventDataList = this.formateList(res.result.eventLst);
          }
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    //格式化数据 表格中 同个时间同个国家合并单元格用
    formateList(data) {
      data.forEach((item, index) => {
        let time = data[index].time_show;
        let country = data[index].country;
        let len = data.length;
        item.rowspan = 1;
        if (
          index != 0 &&
          data[index - 1].time_show == time &&
          data[index - 1].country == country
        ) {
          //该条数据的时间和国家与上一条相同 直接返回0  不参与循环
          item.rowspan = 0;
        } else {
          for (let i = index + 1; i < len; i++) {
            if (data[i].time_show == time && data[i].country == country) {
              //下一条数据的时间和国家相同 则合并时间单元格
              item.rowspan++;
            } else {
              break;
            }
          }
        }
      });
      return data;
    },
    //获取wap端数据
    getWapDateArr(num, weekDay) {
      let rightDay = this.getAfterDayArr(
        num,
        this.year,
        this.month,
        this.currentDay
      );
      let dateArr = [
        { year: this.year, month: this.month, day: this.currentDay },
      ];
      this.wapDateArr = dateArr.concat(rightDay);
      //获取星期
      const weekName = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      this.wapWeekDay = [];
      for (let i = 0; i <= num; i++) {
        if (weekDay + i > 6) {
          this.wapWeekDay.push(weekName[weekDay + i - 7]);
        } else {
          this.wapWeekDay.push(weekName[weekDay + i]);
        }
      }
    },
    //时间面板取消
    searchCancel() {
      this.searchTimeShow = false;
    },
    // 搜索时间设置
    searchSave(v) {
      let date = formatDate(v).split(" ")[0];
      let dateArr = date.split("-");
      //选中日期  获取新日期数据
      this.currentDay = dateArr[2];
      this.month = dateArr[1];
      this.year = dateArr[0];

      this.searchTimeShow = false;
      //获取当前选中日期数据
      this.getDataList();
      //获取当前选中日期起的3天数据;
      let weekDay = new Date(date).getDay(); //获取周几
      console.log(weekDay);
      this.getWapDateArr(2, weekDay);
    },
    handleShowSearch() {
      this.dialogObj = {
        isShow: true,
        title: "",
        confirmText: "确定",
        cancelText: "重置",
      };
    },
    handleConfirm() {
      this.search = {
        area: this.area,
        star: this.star,
      };
      this.dialogObj = {
        isShow: false,
      };
    },
    handleCancel() {
      this.dialogObj.isShow = true;

      this.area = "全部";
      this.star = "";
    },
    //过滤重要性
    filterStar(item) {
      if (this.search.star) {
        //根据重要性返回
        if (this.search.star == "重要") {
          return item.star >= 3;
        } else {
          return item.star < 3;
        }
      } else {
        //未选择重要性 直接返回
        return true;
      }
    },
  },
  created() {
    //获取当前日期
    this.getCurrentDay();
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "@/assets/scss/calendar.scss";
</style>
